<template>
  <div
    v-if="open"
    class="absolute top-0 h-screen w-screen bg-opacity-60 bg-gray-800 flex flex-col justify-center p-16"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
    style="align-items: center;"
    @onClick="close"
  >
    <div class="shadow rounded-lg bg-white p-4 max-w-fit verySmol:w-[90vw] z-20">
      <!-- title -->
      <div class="text-xl font-normal flex" style="align-items: center">
        <slot name="title" />
      </div>
      <!-- text -->
      <div class="pt-4 px-1 mb-3">
        <slot name="content" />
      </div>
      <!-- actions -->
      <div class="flex">
        <slot name="actions" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {},
  persistent: Boolean
});

const emit = defineEmits(["update:modelValue"]);

const open = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val)
});

watch(open, () => { if (process.client) { window.scrollTo({ top: 0 }) }});

function close() {
  open.value = false;
}
</script>
